<template>
    <el-option
        :key="index"
        :label="source[label]"
        :value="source[value]"
    ></el-option>
</template>

<script>
export default {
    name: "item-component",
    props: {
        index: {
            type: Number,
        },
        source: {
            type: Object,
            default() {
                return {};
            },
        },
        label: {
            type: String,
        },
        value: {
            type: String,
        },
    },
};
</script>
